.App {
  text-align: center;
}

.userForm {
  display: flex;
  flex-direction: column;
  gap: 5px;

  width: 200px;
  margin: 20px auto;
}

.userForm label {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}

.userForm input {
  font-size: 16px;
}

.userForm > * {
  flex: 1;
}

.result {
  font-style: italic;
  white-space: pre-line;
}